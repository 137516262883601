#root {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.special-font {
  color: black;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: .5rem;
}

.title-container {
  padding: 5px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ECB6B4;
  margin-bottom: 10px;
}

.image-container {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.profile-text {
  display: flex;
  align-self: center;
  flex-direction: column;
  color: rgb(63, 63, 63);
  width: 50%;
  margin-right: 7px;
}

.avatar-container {
  width: 25%;
  display: flex;
  justify-content: center;
}

.tiffany-image {
  border-radius: 10px;
  display: block;
  width: 100%;
  height: auto;
}

.bio-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.goals-image {
  border-radius: 10px;
  max-width: 375px;
  max-height: 375px;
  display: block;
  width: 100%;
  height: auto;
}

.journal-image {
  border-radius: 10px;
  margin-right: 1.5rem;
  max-width: 375px;
  max-height: 375px;
  display: block;
  width: 100%;
  height: auto;
}

.goals-header {
  margin-top: 40px;
  margin-bottom: 15px;
}

.bio {
  margin-right: 7px;
  display: flex;
  align-self: center;
  color: rgb(63, 63, 63);
  width: 40%;
}

.bio2 {
  display: flex;
  align-items: center;
  color: rgb(63, 63, 63);
  width: 40%;
}

.send-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.send-header {
  width: 450px;
  margin-top: 25px;
  text-align: center;
  font-weight: bold;
}

.send-message {
  display: flex;
  justify-content: center;
}

.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

form {
  width: 100%;
  display: flex;
  justify-content: center;
}

.form {
  width: 40%;
}

.input-text {
  margin-bottom: 10px;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.submit-button {
  color: white;
  background-color:rgb(63, 63, 63);
  border-radius: 5px;
  border-color: white;
  
}

input.submit-button:hover {
  background-color: rgb(113, 113, 113);
}

input.submit-button:focus {
  outline: none;
  box-shadow: none;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.socials-header {
  margin-top: 1.5rem;
  text-align: center;
  margin-bottom: .5rem;
  font-weight: bold;
}

.socials-container {
  width: 75%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

a {
  width: 7%;
}




/* PHONE SIZE */

@media only screen and (max-width: 700px) {
  .title-text {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
  }

  .title-image {
    width: 65%;
  }

  .image-container {
    width: 100%;
    padding-top: 5px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 20px;
  }

  .avatar-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tiffany-image {
    width: 200px;
    height: 210px;
    margin-bottom: 15px;
  }

  .bio-container {
    margin-top: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .goals-header {
    margin-top: 20px;
  }

  .goals-image {
    width: 80%;
  }

  .bio-container2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bio-container3 {
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
  }

  .journal-image {
    margin-right: 0;
  }

  .bio {
    margin-top: 20px;
    width: 90%;
    text-align: center;
  }

  .profile-text {
    text-align: center;
    width: 98%;
    margin: 5px;
    padding: 0;
  }

  .journal-image {
    width: 80%;
  }

  .bio2 {
    text-align: center;
    margin-top: 20px;
    width: 90%;
  }

  .bio-text {
    width: 90%;
  }

  .send-header {
    font-size: 15px;
  }

  .form {
    width: 90%;
  }

  .input-text {
    width: 100%;
}

  a {
    width: 20%;
  }

}